<template>
    <main class="oilcase">
        <header-cmp 
            :menu="nav_menu"
            :avatar="avatar.value"
            v-if="$route.name !== 'main'"
        />
        <transition	name="page">
            <router-view
            ></router-view>
        </transition>
        <modal-cmp />
    </main>
</template>

<script lang="ts">
import axios from 'axios';
import headerCmp from './components/base/header/header-cmp.vue'
import modalCmp from './components/base/modals/modal-cmp.vue'
import { defineComponent, reactive, onMounted } from 'vue';

export default defineComponent({
    name: 'app',
    setup() {
        const nav_menu = reactive([
            {
                text: 'Тестирование',
                link: '/testing',
                id: 1
            },
            {
                text: 'Самооценка',
                link: 'self-esteem',
                id: 2
            },
            {
                text: 'Диаграмма',
                link: '/diagram',
                id: 3
            },
        ])

        const avatar = reactive({
            value: '' as string
        })

        onMounted(() => {
            axios
                .get('api/profile.json')
                .then((response_avatar) => {
                    avatar.value = response_avatar.data.avatar
                })
        })

        return {
            nav_menu,
            avatar
        }
    },
    methods: {},
    components: {
        'header-cmp': headerCmp,
        'modal-cmp': modalCmp,
    }
});
</script>

<style>
</style>
