
<template>
    <div class="oilcase-id__profile oilcase__container">
        <h1 class="oilcase-id__profile__title">Личный кабинет</h1>
        <tabs-cmp 
            class="oilcase-id__profile__tabs"
            :tabs="tabs"
            :id="tab_id.value"
            @select="setTabId"
        />
        <general-info v-if="tab_id.value === 1" />
        <contact-info v-if="tab_id.value === 2" />
        <edu-info v-if="tab_id.value === 3" />
        <work-info v-if="tab_id.value === 4" />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import tabsCmp from '../../components/ui-components/tabs-cmp/tabs-cmp.vue'
import generalInfo from '../../components/blocks/general-info/general-info.vue'
import contactInfo from '../../components/blocks/contact-info/contact-info.vue'
import eduInfo from '../../components/blocks/edu-info/edu-info.vue'
import workInfo from '../../components/blocks/work-info/work-info.vue'
import onScrollAnimate from '@/unilities/scrollAnimate'

export default defineComponent({
    setup() {
        const tabs = reactive([
            {
                name: 'Общая информация',
                id: 1
            },
            {
                name: 'Контакты',
                id: 2
            },
            {
                name: 'Образование',
                id: 3
            },
            {
                name: 'Место работы',
                id: 4
            },
        ])

        const tab_id = reactive({
            value: 1 as number
        })

        return {
            tabs,
            tab_id
        }
    },
    components: {
        'tabs-cmp': tabsCmp,
        'general-info': generalInfo,
        'contact-info': contactInfo,
        'edu-info': eduInfo,
        'work-info': workInfo
    },
    methods: {
        setTabId(val: number) {
            this.tab_id.value = val
            onScrollAnimate()
        },
    }
})

</script>