<template>
    <div class="oilcase-id-diagram">
        <div class="oilcase-id-diagram__filter">
            <checkbox-cmp 
                v-for="(filter, idx) in digram_filter"
                :key="filter.text"
                @setChecked="setCheckebox"
                :active_field="filter.active"
                :idx="idx"
            >
                <span>{{ filter.text }}</span>
            </checkbox-cmp>
        </div>
        <highcharts :options="chart_options.options"></highcharts>
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue';
import { Chart } from 'highcharts-vue'
import checkboxCmp from '../../components/ui-components/checkbox-cmp/checkbox-cmp.vue'

export default defineComponent({
    setup() {        
        const chart_options = reactive({
            options: {
                chart: {
                    polar: true,
                    type: 'line',
                    backgroundColor: '#2B313A',
                    height: 885,
                    width: 1194
                },
                title: {
                    text: '',
                    x: -80
                },

                pane: {
                    size: '80%'
                },

                xAxis: {
                    categories: [
                        'Интерпретация ГИС в открытых стволах', 
                        'Разработка месторождений нефти и газа', 
                        'Экономический анализ', 
                        'Управление технологиями',
                        'Основы технологии бурения', 
                        'Управление технологиями',
                        'Основы технологии бурения',
                        'Геомоделирование'
                    ],
                    tickmarkPlacement: 'on',
                    lineWidth: 0,
                    labels: {
                        style: {
                            color: '#ffffff' // Set the desired text color here
                        }
                    },
                },

                yAxis: {
                    gridLineInterpolation: 'polygon',
                    lineWidth: 0,
                    min: 0,
                },
                legend: '',
                series: [{
                        type: 'area',
                        name: 'Area',
                        data: [4, 3, 1, 3, 4, 3, 2, 1],
                        pointPlacement: 'between'
                    }, 
                    {
                        type: 'area',
                        name: 'Area',
                        data: [1, 2, 3, 4, 4, 4, 4, 4],
                        pointPlacement: 'between'
                    },
                    {
                        type: 'area',
                        name: 'Area',
                        data: [1, 4, 2, 3, 3, 4, 4, 2],
                        pointPlacement: 'between'
                    }
                ],
            }
        })

        const checked_state = reactive({
            value: false
        })

        const digram_filter = reactive([
            {
                text: 'Самооценка',
                active: true
            },
            {
                text: 'Тестирование',
                active: true
            }
        ])

        return {
            chart_options,
            digram_filter,
            checked_state
        }
    },
    components: {
        highcharts: Chart,
        'checkbox-cmp': checkboxCmp
    },
    methods: {
        setCheckebox(val) {            
            this.digram_filter[val.idx].active = val.active            
        },
    }
})

</script>