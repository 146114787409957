import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, resolveComponent as _resolveComponent, withCtx as _withCtx, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "oilcase-id-diagram" }
const _hoisted_2 = { class: "oilcase-id-diagram__filter" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_checkbox_cmp = _resolveComponent("checkbox-cmp")!
  const _component_highcharts = _resolveComponent("highcharts")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("div", _hoisted_2, [
      (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.digram_filter, (filter, idx) => {
        return (_openBlock(), _createBlock(_component_checkbox_cmp, {
          key: filter.text,
          onSetChecked: _ctx.setCheckebox,
          active_field: filter.active,
          idx: idx
        }, {
          default: _withCtx(() => [
            _createElementVNode("span", null, _toDisplayString(filter.text), 1)
          ]),
          _: 2
        }, 1032, ["onSetChecked", "active_field", "idx"]))
      }), 128))
    ]),
    _createVNode(_component_highcharts, {
      options: _ctx.chart_options.options
    }, null, 8, ["options"])
  ]))
}