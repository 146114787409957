<template>
    <div class="finish-modal">
        <span class="finish-modal__title">Тестирование завершено!</span>
        <span class="finish-modal__grade">Уровень компетентности - 4/4</span>
        <btn-cmp 
            :btn_text="'Закрыть'"
            @click="endTest"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';
import btnCmp from '../../../ui-components/btn-cmp/btn-cmp.vue'
import { mapMutations } from 'vuex';

export default defineComponent({
    components: {
        'btn-cmp': btnCmp
    },
    methods: {
        ...mapMutations('modalModule', ['closeModal']),

        endTest() {
            // this.$router.push('/')
            this.closeModal()
        }
    }
})
</script>