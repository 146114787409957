<template>
    <div class="oilcase-id__edu-info">
        <template v-for="(edu, idx) in general_form" :key="idx">
            <add-btn-cmp 
                v-if="idx !== 0 && general_form.length > 0" 
                class="oilcase-id__work-info__delete"
                :id="idx"
                @delete_in_array="deleteWork"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Удалить</span>
            </add-btn-cmp>
            <template 
                v-for="field in edu.slice(0, 4)"
                :key="field.id"
            >
                <input-cmp 
                    class="oilcase-id__work-info__info-field"
                    v-if="field.type !== 'combobox'"
                    :key="field.id"
                    :input_type="field.type"
                    :input_value="field.value"
                    :input_label="field.label"
                    :input_error="field.error"
                />
                <selector-cmp 
                    class="oilcase-id__work-info__info-field"
                    v-else 
                    :selector_list="field.combo_box"
                    :current_value="field.label"
                    :selector_id="field.id"
                    @select_value="setValue"
                    :seletor_error="field.error"
                />
            </template>
            <div class="oilcase-id__work-info__years">
                <input-cmp 
                    v-for="field in edu.slice(-2)"
                    :key="field.id"
                    :input_type="field.type"
                    :input_value="field.value"
                    :input_label="field.label"
                    :input_error="field.error"
                />
            </div>
        </template>
        <add-btn-cmp
            @push_in_array="addWork"
            :data_interface="general_form[0]"
        >   
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#B1B3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Добавить вуз</span>
        </add-btn-cmp>
        <btn-cmp 
            class="oilcase-id__edu-info__btn"
            :btn_text="'Сохранить'" 
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import inputCmp from '../../ui-components/input-cmp/input-cmp.vue'
import selectorCmp from '../../ui-components/selector-cmp/selector-cmp.vue'
import btnCmp from '../../ui-components/btn-cmp/btn-cmp.vue'
import addBtnCmp from '../../ui-components/add-btn-cmp/add-btn-cmp.vue'

export default defineComponent({
    setup() {
        const general_form = reactive([
            [
                {
                    id: 'university',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Университет',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                    combo_box: [
                        {
                            name: 'Среднее профессиональное образование',
                            id: 1,
                        },
                        {
                            name: 'Бакалавриат',
                            id: 2,
                        },
                        {
                            name: 'Специалитет',
                            id: 3,
                        },
                        {
                            name: 'Магистратура',
                            id: 4,
                        },
                        {
                            name: 'Аспирантура',
                            id: 5,
                        },
                        {
                            name: 'Ординатура',
                            id: 6,
                        },
                    ]
                },
                {
                    id: 'faculty',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Факультет',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                    combo_box: [
                        {
                            name: 1,
                            id: 1,
                        },
                        {
                            name: 2,
                            id: 2,
                        },
                        {
                            name: 3,
                            id: 3,
                        },
                        {
                            name: 4,
                            id: 4,
                        },
                        {
                            name: 5,
                            id: 5,
                        },
                        {
                            name: 6,
                            id: 6,
                        },
                        {
                            name: 'Выпускник',
                            id: 6,
                        },
                    ]
                },
                {
                    id: 'direction',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Направление',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                    combo_box: [
                        {
                            name: 1,
                            id: 1,
                        },
                        {
                            name: 2,
                            id: 2,
                        },
                        {
                            name: 3,
                            id: 3,
                        },
                        {
                            name: 4,
                            id: 4,
                        },
                        {
                            name: 5,
                            id: 5,
                        },
                        {
                            name: 6,
                            id: 6,
                        },
                        {
                            name: 'Выпускник',
                            id: 6,
                        },
                    ]
                },
                {
                    id: 'specialization',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Специализация',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                    combo_box: [
                        {
                            name: 1,
                            id: 1,
                        },
                        {
                            name: 2,
                            id: 2,
                        },
                        {
                            name: 3,
                            id: 3,
                        },
                        {
                            name: 4,
                            id: 4,
                        },
                        {
                            name: 5,
                            id: 5,
                        },
                        {
                            name: 6,
                            id: 6,
                        },
                        {
                            name: 'Выпускник',
                            id: 6,
                        },
                    ]
                },
                {
                    id: 'student',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Студент',
                    pattern: /^[0-9]+$/,
                    valid: false,
                    error: '',
                    // selector: true,
                    combo_box: [
                        {
                            name: 1,
                            id: 1,
                        },
                        {
                            name: 2,
                            id: 2,
                        },
                        {
                            name: 3,
                            id: 3,
                        },
                        {
                            name: 4,
                            id: 4,
                        },
                        {
                            name: 5,
                            id: 5,
                        },
                        {
                            name: 6,
                            id: 6,
                        },
                        {
                            name: 'Выпускник',
                            id: 6,
                        },
                    ]
                },
                {
                    id: "yearStart",
                    value: "",
                    type: "text",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Год поступления",
                    error: ""
                },
                {
                    id: "yearEnd",
                    value: "",
                    type: "email",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Год выпуска",
                    error: ""
                },
            ]
        ])

        return {
            general_form
        }
    },
    components: {
        'input-cmp': inputCmp,
        'btn-cmp': btnCmp,
        'selector-cmp': selectorCmp,
        'add-btn-cmp': addBtnCmp,
    },
    methods: {
        addWork(val) {                        
            this.general_form.push(val)
        },
        deleteWork(val) {
            console.log(val);
            
            this.general_form.splice(val.idx, 1)
        }
    }
})
</script>