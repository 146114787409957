import { openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx, createVNode as _createVNode, normalizeClass as _normalizeClass, createElementVNode as _createElementVNode, renderSlot as _renderSlot } from "vue"

const _hoisted_1 = {
  key: 0,
  class: "oilcase__radio-cmp__icon__inner"
}

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: "oilcase__radio-cmp",
    onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.setValue(_ctx.idx)))
  }, [
    _createElementVNode("div", {
      class: _normalizeClass([{_active: _ctx.active === _ctx.idx }, "oilcase__radio-cmp__icon"])
    }, [
      _createVNode(_Transition, { name: "fade" }, {
        default: _withCtx(() => [
          (_ctx.active === _ctx.idx)
            ? (_openBlock(), _createElementBlock("div", _hoisted_1))
            : _createCommentVNode("", true)
        ]),
        _: 1
      })
    ], 2),
    _renderSlot(_ctx.$slots, "default")
  ]))
}