<template>
    <div class="oilcase__radio-cmp" @click="setValue(idx)">
        <div :class='{_active: active === idx }' class="oilcase__radio-cmp__icon">
            <transition name="fade">
                <div v-if="active === idx" class="oilcase__radio-cmp__icon__inner"></div>
            </transition>
        </div>
        <slot></slot>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'

export default defineComponent({    
    props: {
        active: {
            type: Boolean,
            default: false
        },
        idx: {
            type: Number,
            default: 0
        }
    },
    methods: {
        setValue(idx) {
            this.$emit('setRadio', idx)
        }
    }
})
</script>