import { createElementVNode as _createElementVNode, resolveDynamicComponent as _resolveDynamicComponent, openBlock as _openBlock, createBlock as _createBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, Transition as _Transition, withCtx as _withCtx } from "vue"

const _hoisted_1 = { class: "oilcase-modal__container" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createBlock(_Transition, { name: "fade" }, {
    default: _withCtx(() => [
      (_ctx.modalComponent)
        ? (_openBlock(), _createElementBlock("div", {
            class: "oilcase-modal",
            key: _ctx.modalComponent
          }, [
            _createElementVNode("div", {
              onClick: _cache[0] || (_cache[0] = ($event: any) => (_ctx.closeModal())),
              class: "oilcase-modal__shadow"
            }),
            _createElementVNode("div", _hoisted_1, [
              (_openBlock(), _createBlock(_resolveDynamicComponent(_ctx.modalComponent), { data: _ctx.modalProps }, null, 8, ["data"]))
            ])
          ]))
        : _createCommentVNode("", true)
    ]),
    _: 1
  }))
}