import { vModelDynamic as _vModelDynamic, mergeProps as _mergeProps, createElementVNode as _createElementVNode, withDirectives as _withDirectives, toDisplayString as _toDisplayString, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase-input" }
const _hoisted_2 = ["type"]
const _hoisted_3 = { class: "oilcase-input__label" }
const _hoisted_4 = { class: "oilcase-input__error" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _withDirectives(_createElementVNode("input", _mergeProps({
      class: ["oilcase-input__field", {'_fill-input': _ctx.input_field_value.value.length, '_error-input': _ctx.input_error.length}],
      type: _ctx.input_type,
      "onUpdate:modelValue": _cache[0] || (_cache[0] = ($event: any) => ((_ctx.input_field_value.value) = $event)),
      onKeyup: _cache[1] || (_cache[1] = 
//@ts-ignore
(...args) => (_ctx.setValue && _ctx.setValue(...args)))
    }, _ctx.$attrs), null, 16, _hoisted_2), [
      [_vModelDynamic, _ctx.input_field_value.value]
    ]),
    _createElementVNode("label", _hoisted_3, _toDisplayString(_ctx.input_label), 1),
    _createElementVNode("span", _hoisted_4, _toDisplayString(_ctx.input_error), 1)
  ]))
}