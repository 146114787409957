<template>
    <transition name="fade">
            <div class="oilcase-modal" v-if="modalComponent" :key="modalComponent">
                <div @click="closeModal()" class="oilcase-modal__shadow"></div>
                <div class="oilcase-modal__container">
                    <component :is="modalComponent" :data="modalProps"></component>
                </div>
            </div>
    </transition>
</template>

<script lang="ts">
import { defineComponent } from 'vue';
import { mapMutations, mapGetters } from 'vuex';
import finishModal from './finish-modal/finish-modal.vue'
import attentionModal from './attention-modal/attention-modal.vue'

export default defineComponent({
    data() {
        return {
        }
    },
    components: {
        finishModal,
        attentionModal
    },
    computed: {
        ...mapGetters('modalModule', ['modalComponent', 'modalProps', 'modalShadow']),
    },
    methods: {
        ...mapMutations('modalModule', ['closeModal']),
    },
})
</script>