import { renderSlot as _renderSlot, toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, openBlock as _openBlock, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase-btn__text" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_openBlock(), _createElementBlock("div", {
    class: _normalizeClass(["oilcase-btn", [
            _ctx.btn_type === 'primary' ? '_primary' : '_secondary', 
            _ctx.btn_disabled ? '_disabled' : ''
        ]])
  }, [
    _renderSlot(_ctx.$slots, "default"),
    _createElementVNode("span", _hoisted_1, _toDisplayString(_ctx.btn_text), 1)
  ], 2))
}