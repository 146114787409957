<template>
    <div class="oilcase-id__authorization-page oilcase__container">
        <h1 class="oilcase-id__authorization-page__title">Авторизация</h1>
        <div class="oilcase-id__authorization-page__form">
            <input-cmp 
                v-for="input in form"
                class="oilcase-id__authorization-page__form__input"
                :key="input.id"
                :input_value="input.value"
                :input_required="input.required"
                :input_type="input.type"
                :input_label="input.label"
                :input_pattern="input.pattern"
                :input_error="input.error"
                @update="setValue"
                @blur="validCheck(input)"
            />
            <span class="oilcase-id__authorization-page__form__error" v-if="message.error">{{ message.error }}</span>
        </div> 
        <btn-cmp 
            :btn_text="'Войти'"
            @click="authorizationUser"
            :btn_disabled="disabled_btn.value"
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import inputCmp from '../components/ui-components/input-cmp/input-cmp.vue'
import btnCmp from '../components/ui-components/btn-cmp/btn-cmp.vue'
import axios from 'axios'
import { mapMutations } from 'vuex'

export default defineComponent({
    setup() {
        const form = reactive([
            {
                id: 'username',
                type: 'text',
                value: '',
                required: true,
                valid: false,
                pattern: /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/i,
                label: 'Email',
                error: ''
            },
            {
                id: 'password',
                type: 'password',
                value: '',
                required: true,
                label: 'Пароль',
                pattern: '',
                valid: false,
                error: ''
            },
        ])

        const disabled_btn = reactive({
            value: true
        })

        const message = reactive({
            error: '' as string
        })

        return {
            form,
            disabled_btn,
            message
        }
    },
    components: {
        'input-cmp': inputCmp,
        'btn-cmp': btnCmp
    },
    methods: {
        ...mapMutations('authorizationModule', ['logIn']),

        setValue(val: { type: string; value: string }) {
            for (let i = 0; i < this.form.length; i++) {
                if(this.form[i].type === val.type) {
                    this.form[i].value = val.value
                }
            }
        },
        validCheck(input: { value: string; pattern: string|RegExp, id: string, error: string, required: boolean, valid: boolean}) {              
            const regExp = new RegExp(input.pattern, 'i');       

            if(input.value) {
                if(input.id === 'username' && !input.value.match(/@/gi)) {
                    input.error = 'Поле может содержать только латиницу, цифры и специальные символы'
                } else if(!regExp.test(input.value)){
                    input.error = 'Поле может содержать только латиницу, кириллицу, цифры и пробел'
                } else {
                    input.error = ""

                    if(this.form.every(field => field.error === '')) {
                        this.disabled_btn.value = false
                    }
                }
               
            } else if(!input.value.length && input.required) {   
                input.error = 'Поле обязательно к заполнению'
            } 
        },
        authorizationUser() {
            const fd = new FormData
            
            for(let i = 0; i < this.form.length; i++) {
                fd.append(this.form[i].id, this.form[i].value)
            }

            let user_valid = false

            this.$router.push('/profile')

            axios
                .post('/token', fd)
                .then(token => {
                    this.$emit('set_token', token.data.access_token)
                    this.$cookies.set('hackathon_token', token.data.access_token)
                    user_valid = true
                })
                .catch(err => {
                    this.form[1].error = err.response.data.detail
                })
                .finally(() => {
                    if(user_valid) {
                        this.logIn()
                        this.$cookies.set('hackathon_auth', true)
                        this.$router.push('/')
                    }
                })
        }
    },
    mounted() {
        this.form.every(field => {
            if(field.value) {
                console.log('field');
            }
        })
        this.$emit('preloader', {visible: false, page: 'leader_board'})
    },
})
</script>
