<template>
    <div class="oilcase-id__work-info">
        <template v-for="(work, idx) in work_form" :key="idx">
            <add-btn-cmp 
                v-if="idx !== 0 && work_form.length > 0" 
                class="oilcase-id__work-info__delete"
                :id="idx"
                @delete_in_array="deleteWork"
            >
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                    <path d="M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                </svg>
                <span>Удалить</span>
            </add-btn-cmp>
            <template 
                v-for="field in work.slice(0, 4)"
                :key="field.id"
            >
                <input-cmp 
                    class="oilcase-id__work-info__info-field"
                    v-if="field.type !== 'combobox'"
                    :key="field.id"
                    :input_type="field.type"
                    :input_value="field.value"
                    :input_label="field.label"
                    :input_error="field.error"
                />
                <selector-cmp 
                    class="oilcase-id__work-info__info-field"
                    v-else 
                    :selector_list="field.combo_box"
                    :current_value="field.label"
                    :selector_id="field.id"
                    @select_value="setValue"
                    :seletor_error="field.error"
                />
            </template>
            <checkbox-cmp 
                @setChecked="setCheckebox"
                :active_field="checked_state.value"
            >
                <span>Являюсь сотрудником компании сейчас</span>
            </checkbox-cmp>
            <div class="oilcase-id__work-info__years">
                <input-cmp 
                    v-for="field in work.slice(-2)"
                    :key="field.id"
                    :input_type="field.type"
                    :input_value="field.value"
                    :input_label="field.label"
                    :input_error="field.error"
                />
            </div>
        </template>
        <add-btn-cmp
            @push_in_array="addWork"
            :data_interface="work_form[0]"
        >   
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#B1B3B7" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <span>Добавить место работы</span>
        </add-btn-cmp>
        <btn-cmp
            class="oilcase-id__work-info__btn"
            :btn_text="'Сохранить'" 
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import inputCmp from '../../ui-components/input-cmp/input-cmp.vue'
import selectorCmp from '../../ui-components/selector-cmp/selector-cmp.vue'
import btnCmp from '../../ui-components/btn-cmp/btn-cmp.vue'
import checkboxCmp from '../../ui-components/checkbox-cmp/checkbox-cmp.vue'
import addBtnCmp from '../../ui-components/add-btn-cmp/add-btn-cmp.vue'

export default defineComponent({
    setup() {
        const work_form = reactive([
            [
                {
                    id: "place",
                    value: "",
                    type: "text",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Место работы",
                    error: ""
                },
                {
                    id: "position",
                    value: "",
                    type: "email",
                    pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                    label: "Должность",
                    error: ""
                },
                {
                    id: "responsibilities",
                    value: "",
                    type: "text",
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label: "Ключеваые обязанности",
                    error: ""
                },
                {
                    id: 'rpofile_company',
                    type: 'combobox',
                    value: '',
                    required: true,
                    label: 'Профиль компании',
                    pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
                    valid: false,
                    error: '',
                    combo_box: [
                        {
                            name: 'Среднее профессиональное образование',
                            id: 1,
                        },
                        {
                            name: 'Бакалавриат',
                            id: 2,
                        },
                        {
                            name: 'Специалитет',
                            id: 3,
                        },
                        {
                            name: 'Магистратура',
                            id: 4,
                        },
                        {
                            name: 'Аспирантура',
                            id: 5,
                        },
                        {
                            name: 'Ординатура',
                            id: 6,
                        },
                    ]
                },
                {
                    id: "start_work",
                    value: "",
                    type: "text",
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label: "Начало работы (ММ/ГГ)",
                    error: ""
                },
                {
                    id: "end_work",
                    value: "",
                    type: "text",
                    pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                    label: "Конец работы (ММ/ГГ)",
                    error: ""
                },
            ]
        ])

        const checked_state = reactive({
            value: false
        })

        return {
            work_form,
            checked_state
        }
    },
    components: {
        'input-cmp': inputCmp,
        'selector-cmp': selectorCmp,
        'btn-cmp': btnCmp,
        'checkbox-cmp': checkboxCmp,
        'add-btn-cmp': addBtnCmp,
    },
    methods: {
        setCheckebox(val) {
            this.checked_state.value = val
        },
        addWork(val) {                        
            this.work_form.push(val)
        },
        deleteWork(val) {
            this.work_form.splice(val.idx, 1)
        }
    }
})
</script>