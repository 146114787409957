<template>
    <div class="oilcase-id__contact-info">
        <input-cmp 
            class="oilcase-id__contact-info__info-field"
            v-for="field in general_form.slice(0, 4)"
            :key="field.id"
            :input_type="field.type"
            :input_value="field.value"
            :input_label="field.label"
            :input_error="field.error"
        />
        <btn-cmp 
            class="oilcase-id__contact-info__btn"
            :btn_text="'Сохранить'" 
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import inputCmp from '../../ui-components/input-cmp/input-cmp.vue'
import btnCmp from '../../ui-components/btn-cmp/btn-cmp.vue'

export default defineComponent({
    setup() {
        const general_form = reactive([
            {
                id: "phone",
                value: "",
                type: "text",
                pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                label: "Номер телефона",
                error: ""
            },
            {
                id: "VK",
                value: "",
                type: "email",
                pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                label: "VK",
                error: ""
            },
        ])

        return {
            general_form
        }
    },
    components: {
        'input-cmp': inputCmp,
        'btn-cmp': btnCmp,
    }
})
</script>