import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createVNode as _createVNode, Transition as _Transition, withCtx as _withCtx, createElementBlock as _createElementBlock } from "vue"

const _hoisted_1 = { class: "oilcase" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_header_cmp = _resolveComponent("header-cmp")!
  const _component_router_view = _resolveComponent("router-view")!
  const _component_modal_cmp = _resolveComponent("modal-cmp")!

  return (_openBlock(), _createElementBlock("main", _hoisted_1, [
    (_ctx.$route.name !== 'main')
      ? (_openBlock(), _createBlock(_component_header_cmp, {
          key: 0,
          menu: _ctx.nav_menu,
          avatar: _ctx.avatar.value
        }, null, 8, ["menu", "avatar"]))
      : _createCommentVNode("", true),
    _createVNode(_Transition, { name: "page" }, {
      default: _withCtx(() => [
        _createVNode(_component_router_view)
      ]),
      _: 1
    }),
    _createVNode(_component_modal_cmp)
  ]))
}