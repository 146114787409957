<template>
    <header class="oilcase-header">
        <div class="oilcase-header__left">
            <svg class="oilcase-header__logo" xmlns="http://www.w3.org/2000/svg" width="64" height="64" viewBox="0 0 64 64" fill="none">
                <circle cx="32" cy="32" r="32" fill="#0F4B85"/>
                <circle cx="32.0005" cy="31.9999" r="28.1176" stroke="white" stroke-width="4"/>
                <path d="M35.6891 38.3198H35.9391V38.0698V25.856V25.606H35.6891H33.5554H33.3054V25.856V38.0698V38.3198H33.5554H35.6891ZM37.9168 25.606H37.6668V25.856V38.0698V38.3198H37.9168H44.9897C48.5844 38.3198 51.187 35.5069 51.187 31.8369C51.187 30.0038 50.5387 28.443 49.4313 27.34C48.3242 26.2373 46.7721 25.606 44.9897 25.606H37.9168ZM22.0881 38.3198C25.7155 38.3198 28.319 35.5078 28.319 31.8369C28.319 30.0053 27.6842 28.444 26.5782 27.3402C25.4722 26.2364 23.9111 25.606 22.0881 25.606H19.1144C17.3498 25.606 15.8019 26.2241 14.6944 27.3206C13.5863 28.4176 12.934 29.9789 12.934 31.8369C12.934 35.5069 15.5533 38.3198 19.1144 38.3198H22.0881ZM19.1144 35.6861C17.0644 35.6861 15.5676 34.1143 15.5676 31.9377C15.5676 29.7501 17.0756 28.2396 19.1144 28.2396H22.0881C24.1948 28.2396 25.6853 29.7676 25.6853 31.9377C25.6853 34.112 24.1907 35.6861 22.0881 35.6861H19.1144ZM31.5757 37.0618C31.5757 36.2829 30.9574 35.6693 30.1665 35.6693C29.3852 35.6693 28.7909 36.2902 28.7909 37.0618C28.7909 37.8334 29.3852 38.4542 30.1665 38.4542C30.9574 38.4542 31.5757 37.8406 31.5757 37.0618ZM40.2836 35.6861V28.2396H44.9897C47.0453 28.2396 48.5534 29.7669 48.5534 31.9377C48.5534 34.1128 47.0412 35.6861 44.9897 35.6861H40.2836Z" fill="white" stroke="white" stroke-width="0.5"/>
            </svg>
            <nav class="oilcase-header__navigation">
                <a 
                    class="oilcase__link" 
                    v-for="nav in menu" 
                    :key="nav.id"
                    :href="nav.link"
                >{{ nav.text }}</a>
            </nav>
        </div>

        <div class="oilcase-header__profile" @click="openMenu">
            <div class="oilcase-header__profile__avatar">
                <img :src="avatar" />
            </div>
            <svg :class="{_active: open_profile_list.state}" class="oilcase-header__profile__menu" xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                <path d="M6 9L12 15L18 9" stroke="#ffffff" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
            </svg>
            <transition name="fadeHeight">
                <div class="oilcase-header__profile__list" v-if="open_profile_list.state">
                    <a :href="menu.link" class="oilcase__link" v-for="menu in menu_profile" :key="menu.text">
                        <div v-html="menu.icon"></div>
                        {{ menu.text }}
                    </a>
                </div>
            </transition>
        </div>
    </header>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'

export default defineComponent({
    props: {
        menu: {
            type: Array,
            default: () => []
        },
        avatar: {
            type: String,
            default: ''
        }
    },
    setup() {
        const open_profile_list = reactive({
            state: false
        })

        const menu_profile = reactive([
            {
                text: 'Личный кабинет',
                link: '/profile',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M5.3163 19.4384C5.92462 18.0052 7.34492 17 9 17H15C16.6551 17 18.0754 18.0052 18.6837 19.4384M16 9.5C16 11.7091 14.2091 13.5 12 13.5C9.79086 13.5 8 11.7091 8 9.5C8 7.29086 9.79086 5.5 12 5.5C14.2091 5.5 16 7.29086 16 9.5ZM22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
            {
                text: 'Выйти',
                link: '#',
                icon: '<svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"><path d="M18 8L22 12M22 12L18 16M22 12H9M15 4.20404C13.7252 3.43827 12.2452 3 10.6667 3C5.8802 3 2 7.02944 2 12C2 16.9706 5.8802 21 10.6667 21C12.2452 21 13.7252 20.5617 15 19.796" stroke="#808E9D" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/></svg>'
            },
        ])

        return {
            open_profile_list,
            menu_profile
        }
    },
    methods: {
        openMenu() {
            this.open_profile_list.state = !this.open_profile_list.state
        }
    }
})
</script>
