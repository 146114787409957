<template>
    <div class="oilcase__checkbox" @click="activeCheckbox">
        <div 
            class="oilcase__checkbox__field"
            :class="{_active: active_field}"
        >
            <transition name="fade">
                <svg
                    class="oilcase__checkbox__field__icon"
                    v-if="active_field"
                    width="12"
                    height="10"
                    viewBox="0 0 12 10"
                    fill="none"
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <path
                        d="M4.0001 7.7799L1.2201 4.9999L0.273438 5.9399L4.0001 9.66656L12.0001 1.66656L11.0601 0.726562L4.0001 7.7799Z"
                        fill="#03AEE2"
                    />
                </svg>
            </transition>
        </div>
        <slot></slot>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        active_field: {
            type: Boolean,
            default: false
        },
        idx: {
            type: Number,
            default: null
        }
    },
    methods: {
        activeCheckbox() {
            this.$props.idx === null ? this.$emit('setChecked', !this.$props.active_field ? true : false) : this.$emit('setChecked', {active: !this.$props.active_field ? true : false, idx: this.$props.idx})
        }
    }
    
})
</script>