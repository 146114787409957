<template> 
    <div class="attention-modal">
        <span class="attention-modal__title">Внимание!</span>
        <span class="attention-modal__desc">Если вы завершите тестирование, ответы на вопросы не сохранятся!</span>
        <div class="attention-modal__btns">
            <btn-cmp 
                :btn_text="'Отменить'"
                :btn_type="'secondary'"
                @click="closeModal()"
            />
            <btn-cmp 
                :btn_text="'Завершить'"
                @click="endTest"
            />
        </div>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue'
import { mapMutations } from 'vuex'
import btnCmp from '../../../ui-components/btn-cmp/btn-cmp.vue'

export default defineComponent({
    components: {
        'btn-cmp': btnCmp
    },
    methods: {
        ...mapMutations('modalModule', ['closeModal']),

        endTest() {
            this.$router.push('/')
            this.closeModal()
        }
    }
})
</script>