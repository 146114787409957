<template>
    <div class="oilcase-id__general-info">
        <input-cmp 
            class="oilcase-id__general-info__info-field"
            v-for="field in general_form.slice(0, 4)"
            :key="field.id"
            :input_type="field.type"
            :input_value="field.value"
            :input_label="field.label"
            :input_error="field.error"
        />
        <textarea-cmp 
            class="oilcase-id__general-info__info-field"
            v-for="field in general_form.slice(-1)"
            :key="field.id"
            :input_type="field.type"
            :input_value="field.value"
            :input_label="field.label"
            :input_error="field.error"
        />
        <btn-cmp 
            class="oilcase-id__general-info__btn"
            :btn_text="'Сохранить'" 
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import inputCmp from '../../ui-components/input-cmp/input-cmp.vue'
import textareaCmp from '../../ui-components/textarea-cmp/textarea-cmp.vue'
import btnCmp from '../../ui-components/btn-cmp/btn-cmp.vue'

export default defineComponent({
    setup() {
        const general_form = reactive([
            {
                id: "Surname",
                value: "",
                type: "text",
                pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                label: "Фамилия",
                error: ""
            },
            {
                id: "firstName",
                value: "",
                type: "email",
                pattern: '^([a-z0-9]+(?:[._-][a-z0-9]{1,50})*)@([a-z0-9]{4,31}(?:[.-][a-z0-9]{4,31})*.[a-z]{2,4})$',
                label: "Имя",
                error: ""
            },
            {
                id: "Patronic",
                value: "",
                type: "text",
                pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                label: "Отчество",
                error: ""
            },
            {
                id: "birthday",
                value: "",
                type: "text",
                pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                label: "Год рождения",
                error: ""
            },
            {
                id: "about",
                value: "",
                type: "text",
                pattern: "^[-. ()а-яА-ЯёЁa-zA-Z]+$",
                label: "О себе",
                error: ""
            },
        ])

        return {
            general_form
        }
    },
    components: {
        'input-cmp': inputCmp,
        'textarea-cmp': textareaCmp,
        'btn-cmp': btnCmp,
    }
})
</script>