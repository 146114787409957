import main_page from "./pages/main_page.vue"
import profile_page from "./pages/profile-page/profile_page.vue"
import diagram_page from "./pages/diagram-page/diagram-page.vue"
import self_page from './pages/self-esteem-page/self-esteem-page.vue'
import testing_page from "./pages/testing-page/testing-page.vue"

const routes = [
    {
        path: '/',
        name: 'main',
        component: main_page
    },
    {
        path: '/profile',
        name: 'profile',
        component: profile_page
    },
    {
        path: '/diagram',
        name: 'diagram',
        component: diagram_page
    },
    {
        path: '/self-esteem',
        name: 'self_esteem',
        component: self_page
    },
    {
        path: '/testing',
        name: 'testing_page',
        component: testing_page
    },
]

export default routes