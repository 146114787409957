<template>
    <div 
        class="oilcase__add-btn"
        :class="{_delete: !data_interface.length}" 
        @click="pushInArray"
    >
        <slot></slot>
    </div>
</template>
<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({
    props: {
        data_interface: {
            type: Array,
            default: () => []
        },
        id: {
            type: Number,
            default: 0
        }
    },
    methods: {
        pushInArray() {
            if(this.$props.data_interface.length) {
                this.$emit('push_in_array', this.$props.data_interface)
            } else {
                this.$emit('delete_in_array', {interface: this.$props.data_interface, id: this.$props.id})
            }
           
        }
    }
})

</script>