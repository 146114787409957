<template> 
    <div class="oilcase-id__self-esteem">
        <h1 class="oilcase-id__self-esteem__title">Личный кабинет</h1>
        <div class="oilcase-id__self-esteem__results">
            <div class="oilcase-id__self-esteem__results__block">
                <h2 class="oilcase-id__self-esteem__results__block__title">Библиотека компетенция</h2>
                <span class="oilcase-id__self-esteem__results__block__desc">Выберите компетенции для проведения самооценки</span>
                <selector-cmp 
                    class="oilcase-id__self-esteem__results__block__selector"
                    :selector_list="select_self_eteems.combo_box"
                    :current_value="select_self_eteems.label"
                    :selector_id="select_self_eteems.id"
                    @select_value="setValue"
                    :seletor_error="select_self_eteems.error"
                />
                <template v-if="select.value === 1">
                    <div 
                        v-for="list in competention_list"
                        :key="list.id"
                        class="oilcase-id__self-esteem__results__block__competention"
                        @click="pushInCompetention(list)"
                    >
                        <span>{{ list.text }}</span>
                        <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none">
                            <path d="M12 8V16M8 12H16M22 12C22 17.5228 17.5228 22 12 22C6.47715 22 2 17.5228 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12Z" stroke="white" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>    
                </template>
            </div>
            <div class="oilcase-id__self-esteem__results__block">
                <h2 class="oilcase-id__self-esteem__results__block__title">Ваши компетенции</h2>
                <span class="oilcase-id__self-esteem__results__block__desc">Здесь будут отображаться выбранные компетенции</span>
                <div class="oilcase-id__self-esteem__results__block__active-competention">
                    <div class="oilcase-id__self-esteem__results__block__active-competention__header">
                        <span>Компетенция</span>
                        <span>Уровень</span>
                    </div>
                    <div 
                        v-for="(competention, idx_active) in active_competention_list" 
                        :key="competention.id"
                        class="oilcase-id__self-esteem__results__block__active-competention__content"
                    >
                        <span>{{ competention.text }}</span>
                        <div 
                            class="oilcase-id__self-esteem__results__block__active-competention__content__level" 
                            @click="openSelector(competention.id)"
                        >
                            <span>{{ competention.level ? competention.level : '—' }}</span>
                            <svg class="oilcase-id__self-esteem__results__block__active-competention__content__level__icon" :class="{_active: open_list.value === competention.id}" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                <path d="M5 7.5L10 12.5L15 7.5" stroke="#CCD6E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                            </svg>
                        </div>
                        <div 
                            v-if="competention.id === open_list.value"
                            class="oilcase-id__self-esteem__results__block__active-competention__content__subrow"
                        >
                            <div 
                                v-for="(desc, idx) in level_competention" 
                                :key="idx"
                                class="oilcase-id__self-esteem__results__block__active-competention__content__subrow__inner"
                            >
                                <radio-cmp
                                    :active="level_select.value"
                                    :idx="idx"
                                    @setRadio='selectLevelCompetention'
                                >
                                    <span>{{ desc.desc_text }}</span>
                                </radio-cmp>
                                <svg 
                                    @click="openBlock(idx + 1)"
                                    :class="{_active: open_block.value === (idx + 1)}"
                                    class="oilcase-id__self-esteem__results__block__active-competention__content__subrow__inner__icon" xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20" fill="none">
                                    <path d="M5 7.5L10 12.5L15 7.5" stroke="#CCD6E1" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                                </svg>
                                <transition name="fadeHeight">
                                    <div 
                                        v-if="open_block.value === (idx + 1)"
                                        class="oilcase-id__self-esteem__results__block__active-competention__content__subrow__inner__detailed"
                                    >
                                        <span>{{ desc.details_text }}</span>
                                    </div>
                                </transition>
                            </div>
                        </div>
                        <svg @click="deleteObj(idx_active)" xmlns="http://www.w3.org/2000/svg" width="23" height="22" viewBox="0 0 23 22" fill="none">
                            <path d="M8.31371 8.17157L13.9706 13.8284M8.31371 13.8284L13.9706 8.17157M18.2132 3.92893C22.1184 7.83418 22.1184 14.1658 18.2132 18.0711C14.308 21.9763 7.97631 21.9763 4.07107 18.0711C0.165825 14.1658 0.165825 7.83418 4.07107 3.92893C7.97631 0.023689 14.308 0.0236893 18.2132 3.92893Z" stroke="#9AA7BB" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"/>
                        </svg>
                    </div>
                </div>
            </div>
        </div>
        <btn-cmp 
            class="oilcase-id__self-esteem__results__btn"
            :btn_text="'Сохранить'" 
        />
    </div>
</template>
<script lang="ts">
import { defineComponent, reactive } from 'vue'
import selectorCmp from '../../components/ui-components/selector-cmp/selector-cmp.vue'
import radioCmp from '../../components/ui-components/radio-cmp/radio-cmp.vue'
import buttonCmp from '../../components/ui-components/btn-cmp/btn-cmp.vue'

export default defineComponent({
    setup() {
        const select_self_eteems = reactive({
            id: 'university',
            type: 'combobox',
            value: '',
            required: true,
            label: 'Категория компетенций',
            pattern: /^[A-Za-zА-Яа-я0-9\s]+$/,
            valid: false,
            error: '',
            combo_box: [
                {
                    name: 'Моделирование сейсмических скоростей и глубинные построения',
                    id: 1,
                },
                {
                    name: 'Основы технологии бурения',
                    id: 2,
                },
                {
                    name: 'Общие вопросы интерпретации данных сейсморазведки',
                    id: 3,
                },
            ]
        })

        const level_select = reactive({
            value: 0
        })
        
        const competention_list = reactive([
            {
                text: 'Прогнозирование порового давления',
                id: 1,
                level: 1
            },
            {
                text: 'Моделирование сейсмических скоростей и глубинные построения',
                id: 2,
                level: 0
            },
        ])

        const level_competention = [
            {
                desc_text: 'Осведомлённость',
                details_text: 'Оценка глубины и смещения глубины с использованием данных ГИС открытого ствола. Описание физических основ и принципов работы зондов для ГИС в открытых стволах. Понимание задач и сущности основных диаграмм ГИС в открытых стволах. Анализ влияния состава пород, поровых флюидов и состояния ствола скважины на данные ГИС. Умение интерпретировать литологию по данным ГИС в открытых стволах. Умение интерпретировать глинистость, пористость и водонасыщенность по данным ГИС. Понимание ограничений методов ГИС в открытых стволах. Описание взаимосвязей между петрофизикой, разработкой и другими геологическими науками.'
            },
            {
                desc_text: 'Базовое применение',
                details_text: 'Оценка глубины и смещения глубины с использованием данных ГИС открытого ствола. Описание физических основ и принципов работы зондов для ГИС в открытых стволах. Понимание задач и сущности основных диаграмм ГИС в открытых стволах. Анализ влияния состава пород, поровых флюидов и состояния ствола скважины на данные ГИС. Умение интерпретировать литологию по данным ГИС в открытых стволах. Умение интерпретировать глинистость, пористость и водонасыщенность по данным ГИС. Понимание ограничений методов ГИС в открытых стволах. Описание взаимосвязей между петрофизикой, разработкой и другими геологическими науками.'
            },
            {
                desc_text: 'Квалифицированное применение',
                details_text: 'Оценка глубины и смещения глубины с использованием данных ГИС открытого ствола. Описание физических основ и принципов работы зондов для ГИС в открытых стволах. Понимание задач и сущности основных диаграмм ГИС в открытых стволах. Анализ влияния состава пород, поровых флюидов и состояния ствола скважины на данные ГИС. Умение интерпретировать литологию по данным ГИС в открытых стволах. Умение интерпретировать глинистость, пористость и водонасыщенность по данным ГИС. Понимание ограничений методов ГИС в открытых стволах. Описание взаимосвязей между петрофизикой, разработкой и другими геологическими науками.'
            },
            {
                desc_text: 'Мастерство',
                details_text: 'Оценка глубины и смещения глубины с использованием данных ГИС открытого ствола. Описание физических основ и принципов работы зондов для ГИС в открытых стволах. Понимание задач и сущности основных диаграмм ГИС в открытых стволах. Анализ влияния состава пород, поровых флюидов и состояния ствола скважины на данные ГИС. Умение интерпретировать литологию по данным ГИС в открытых стволах. Умение интерпретировать глинистость, пористость и водонасыщенность по данным ГИС. Понимание ограничений методов ГИС в открытых стволах. Описание взаимосвязей между петрофизикой, разработкой и другими геологическими науками.'
            },
        ]

        const select = reactive({
            value: 1
        })

        const open_list = reactive({
            value: false
        })

        const open_block = reactive({
            value: 0
        })

        const active_competention_list = reactive([] as any)

        return {
            select_self_eteems,
            competention_list,
            select,
            active_competention_list,
            open_list,
            level_competention,
            open_block,
            level_select
        }
    },
    components: {
        'selector-cmp': selectorCmp,
        'radio-cmp': radioCmp,
        'btn-cmp': buttonCmp,
    },
    methods: {
        pushInCompetention(obj: {text: string,id: number, level: number}) {
            if(!this.active_competention_list.find(item => item.id === obj.id)) {
                this.active_competention_list.push(obj)
            }
        },
        openSelector(idx) {
            this.open_list.value = this.open_list.value === idx ? 0 : idx
        },
        openBlock(idx) {            
            this.open_block.value = this.open_block.value === idx ? 0 : idx
        },
        selectLevelCompetention(val) {
            // this.active_competention_list[this.open_list.value].level = this.level_select.value
            this.level_select.value = this.level_select.value === val ? 0 : val
        },
        deleteObj(id) {
            console.log(id);
            
            this.active_competention_list.splice(id, 1)
        }
    }
})
</script>