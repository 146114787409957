import { renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, resolveComponent as _resolveComponent, createBlock as _createBlock, createVNode as _createVNode } from "vue"

const _hoisted_1 = { class: "oilcase-id__contact-info" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_input_cmp = _resolveComponent("input-cmp")!
  const _component_btn_cmp = _resolveComponent("btn-cmp")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    (_openBlock(true), _createElementBlock(_Fragment, null, _renderList(_ctx.general_form.slice(0, 4), (field) => {
      return (_openBlock(), _createBlock(_component_input_cmp, {
        class: "oilcase-id__contact-info__info-field",
        key: field.id,
        input_type: field.type,
        input_value: field.value,
        input_label: field.label,
        input_error: field.error
      }, null, 8, ["input_type", "input_value", "input_label", "input_error"]))
    }), 128)),
    _createVNode(_component_btn_cmp, {
      class: "oilcase-id__contact-info__btn",
      btn_text: 'Сохранить'
    })
  ]))
}